@import './const.scss';
body {
    background-color: #F4F8FD;
    color: #1f3d62;
}

.card {
    box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);
    border: 0;
}

.btn-primary {
    background-color: #000;
    border-color: #000;
}

.btn-primary:hover {
    background-color: #181b28;
    border-color: #131620;
}

.btn-primary:focus {
    background-color: #1F3D62;
    border-color: #1F3D62;
}

// errors
.input-error{
    color: $errorColor;
    font-size: $errorSize;
}

// cursor pointer
.cursor-pointer{
    cursor: pointer;
}

// pagination
.pagination-wrapper{
    text-align: center;
}
