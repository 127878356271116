.auth_wrapper {
  height: 100vh;

  .form-group {
    margin-top: 20px;
  }
}

// loader=======================================
.loader_lg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}

// ============================== spin========================
.spin {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

// ==============================spin========================

.site_wrapper {
  width: 100%;

  .header {
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 5px 20px #92b7b91a;
    background-color: hsla(0, 0%, 100%, 0.9);
    z-index: 9;

    .header-inner-wrapper {
      display: flex;
      align-items: center;
      padding: 0 10px;

      .logo-wrapper {
        min-width: 250px;
        height: 60px;
        padding: 5px;

        .logo-img {
          height: 100%;
        }

        .logo-text {
          margin-left: 15px;
          font-size: 16px;
          margin-bottom: 0;
        }
      }

      .mobile_trigger {
        cursor: pointer;
      }

      .logout {
        cursor: pointer;
      }
    }
  }

  .main-cointainer {
    min-height: 100vh;
    padding-top: 90px;

    .sidebar {
      position: fixed;
      width: 250px;
      height: 100%;
      z-index: 9;
      top: 0;
      left: 0;
      background-color: #ffffff;
      transition: all ease-in-out 200ms;

      .logo-wrapper {
        padding: 10px;

        .logo-img {
          width: 35px;
        }

        .logo-text {
          font-size: 20px;
          margin-bottom: 0;
          padding-left: 15px;
        }
      }

      .menu-toggle-icons {
        position: absolute;
        right: -30px;
        top: 20px;
        .toggle-icon {
          font-size: 20px;
        }
      }

      &.hide {
        left: -250px;
        transition: all ease-in-out 200ms;
      }

      .sidebar-scroll {
        overflow: auto;
        height: 90%;

        .items {
          .item {
            margin-top: 20px;

            .nav-item {
              padding: 10px 10px 10px 20px;
              display: flex;
              align-items: center;
              width: 100%;
              color: #1f3d62;
              text-decoration: none;
              transition: all ease-in-out 200ms;

              &.active,
              &:hover {
                background-color: #1f3d62;
                color: #ffffff;
                transition: all ease-in-out 200ms;
              }

              .menu_text {
                margin-left: 15px;
              }
            }
          }
        }
      }
    }

    .content-wrapper {
      width: calc(100% - 250px);
      transition: all ease-in-out 200ms;

      &.full-content {
        width: 100%;
        transition: all ease-in-out 200ms;
      }

      .content-route {
        padding: 0 25px;

        .page-header {
          padding: 1rem 1.5rem;

          .page-title {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.search {
  position: relative;

  .search-spiner {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.media-preview {
  width: 320px;
  height: 320px;
  background-color: #a17979;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-preview,
  .video-preview {
    width: 100%;
    height: 100%;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.table {
  tr {
    &.disabled-row {
      background: #c7c4c4;
    }
  }
}

// notification
.notification-list {
  margin-left: -16px;
  margin-right: -16px;
  .notification-item {
    font-size: 14px;
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    &.unread {
      background-color: rgba(12, 7, 7, 0.1);
    }
  }
}

.thc-sidebar {
  &__accordion {
    &--item {
      border: none;

      li.item:first-child {
        margin-top: 0 !important;
      }

      button.accordion-button {
        color: #1f3d62;
        padding: 10px 10px 10px 20px;
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        font-size: 14px;
        transition: all 0.2s ease-in-out;

        &:not(.collapsed) {
          color: inherit;
          background-color: unset;
          box-shadow: unset;
        }
      }
    }
  }
}
